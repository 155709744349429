import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { ROUTE } from 'routes/helpers';
import { ContainerData, createContainer } from 'api/container';
import { TextInput } from 'components/form';
import { Button } from 'components/general/Button';
import { Modal } from 'components/general/Modal';
import { TooltipType } from 'components/general/Tooltip';
import { AuthContext } from 'contexts/auth';
import { CommonContext } from 'contexts/common';
import { TooltipsContext } from 'contexts/tooltips';

export const CreateContainerModal: FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { showTooltip } = useContext(TooltipsContext);
  const { setActiveContainer, fetchContainers } = useContext(CommonContext);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ContainerData>({ defaultValues: { name: '' } });

  const handleUpdateContainer = useCallback(
    async (values: Pick<ContainerData, 'name'>) => {
      if (!user?.id) return;

      try {
        const { data, request } = await createContainer({
          ...values,
          userId: user.id,
        });

        if (data) {
          setActiveContainer(data);
          navigate(`${ROUTE.CONTAINERS}/${data.id}`);

          fetchContainers();
        } else {
          setError('root', { message: request.responseText });
        }
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    },
    [
      setActiveContainer,
      fetchContainers,
      user?.id,
      showTooltip,
      navigate,
      setError,
    ]
  );

  return (
    <Modal onClose={onClose}>
      <h2 className="font-bold text-[18px] text-center mb-32">
        Create Container
      </h2>
      <form
        className="flex flex-col gap-16 items-center w-[320px]"
        onSubmit={handleSubmit(handleUpdateContainer)}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Container name is required',
          }}
          render={({ field }) => (
            <TextInput label="Container name" field={field} errors={errors} />
          )}
        />
        {errors.root && (
          <p className="text-[12px] font-medium text-red-500">
            {errors.root.message}
          </p>
        )}
        <div className="flex gap-8 text-[14px]">
          <Button green className="min-w-[64px]" type="submit">
            Save
          </Button>
          <Button
            className="min-w-[64px]"
            orange
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};
