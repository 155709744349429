import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { FC, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

import { FieldProps, TextInput } from './TextInput';

interface PasswordInputProps {
  field: FieldProps;
  errors?: FieldErrors;
  label?: string;
}
export const PasswordInput: FC<PasswordInputProps> = ({
  field,
  errors,
  label = 'Password',
}) => {
  const [showPassword, toggleShowPassword] = useState<boolean>(false);

  return (
    <TextInput
      field={field}
      label={label}
      errors={errors}
      type={showPassword ? 'text' : 'password'}
      icon={
        showPassword ? (
          <EyeSlashIcon
            className="w-24 h-24 cursor-pointer"
            onClick={() => toggleShowPassword(false)}
          />
        ) : (
          <EyeIcon
            className="w-24 h-24 cursor-pointer"
            onClick={() => toggleShowPassword(true)}
          />
        )
      }
    />
  );
};
