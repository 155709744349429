import { FC, useContext, useMemo, useState } from 'react';

import { Table } from 'components/general/Table';
import { CommonContext } from 'contexts/common';
import { LandingActions } from './LandingActions';
import { Button } from 'components/general/Button';
import { CreateLandingModal } from './CreateLandingModal';

export const Landings: FC = () => {
  const { landings } = useContext(CommonContext);
  const [isCreateLandingModalOpen, toggleCreateLandingModal] = useState(false);

  const tableData = useMemo(
    () =>
      landings.map(({ id, url, name }, index) => ({
        index: { value: index + 1 },
        id: { value: id },
        url: { value: url },
        name: { value: name },
        actions: { label: <LandingActions landingId={id} /> },
      })),
    [landings]
  );

  return (
    <div className="flex flex-col w-full gap-32">
      <header className="w-full pb-16 border-b-2 border-gray-200 flex gap-32">
        <h1 className="w-full text-[24px] font-bold">Landings</h1>
        <Button
          onClick={() => toggleCreateLandingModal(true)}
          className="text-sm shrink-0"
        >
          Create Landing
        </Button>
      </header>
      <Table
        withSearch
        data={tableData}
        columns={[
          { header: '№', accessor: 'index', classNames: 'w-[80px]' },
          { accessor: 'id', classNames: 'w-[120px]' },
          { accessor: 'name', classNames: 'w-[30%]' },
          { accessor: 'url' },
          {
            accessor: 'actions',
            disableSort: true,
            classNames: 'w-[132px]',
            headerClassNames: 'justify-center',
          },
        ]}
      />
      {isCreateLandingModalOpen && (
        <CreateLandingModal onClose={() => toggleCreateLandingModal(false)} />
      )}
    </div>
  );
};
