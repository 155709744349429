import { FC, ButtonHTMLAttributes, useMemo } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  isLoading?: boolean;
  icon?: React.ReactNode;

  //mode
  blue?: boolean;
  green?: boolean;
  red?: boolean;
  orange?: boolean;
  gray?: boolean;
  noBg?: boolean;
}

const mapButtonStyles = {
  blue: 'bg-blue-600 hover:bg-blue-700',
  green: 'bg-emerald-600 hover:bg-emerald-700',
  red: 'bg-red-600 hover:bg-red-700',
  orange: 'bg-orange-500 hover:bg-orange-600',
  gray: 'bg-gray-500 hover:bg-gray-600',
  noBg: 'bg-transparent hover:text-gray-500',
};

export const Button: FC<ButtonProps> = ({
  children,
  onClick,
  icon,
  className,
  blue,
  green,
  red,
  orange,
  gray,
  noBg,
  ...buttonProps
}) => {
  const buttonStyles = useMemo(() => {
    if (blue) return mapButtonStyles.blue;
    if (green) return mapButtonStyles.green;
    if (red) return mapButtonStyles.red;
    if (orange) return mapButtonStyles.orange;
    if (gray) return mapButtonStyles.gray;
    if (noBg) return mapButtonStyles.noBg;

    return mapButtonStyles.blue;
  }, [blue, green, red, orange, gray, noBg]);

  return (
    <button
      className={`flex items-center justify-center min-h-[40px] gap-8 py-4 rounded-md font-semibold focus:outline-none focus:ring-0 disabled:opacity-50 disabled:pointer-events-none
			${buttonStyles} ${noBg ? 'text-gray-900' : 'text-white'} ${
        children ? 'min-w-[82px] px-16' : 'px-8'
      } ${className || ''}`}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
      {icon && <div className="object-contain w-24 h-24">{icon}</div>}
    </button>
  );
};
