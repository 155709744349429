import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Table } from 'components/general/Table';
import { AuthContext } from 'contexts/auth';
import { UserActions } from 'components/user/UserActions';
import { CreateUserModal } from './components/CreateUserModal';
import { Button } from 'components/general/Button';

export const Users: FC = () => {
  const { users, fetchUsers } = useContext(AuthContext);

  const [isCreateUserModalOpen, toggleCreateUserModal] = useState(false);

  const handleCloseModal = () => toggleCreateUserModal(false);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const tableData = useMemo(
    () =>
      users.map(({ id, name, email, role }, index) => ({
        index: { value: index + 1 },
        id: { value: id },
        name: { value: name },
        email: { value: email },
        role: { value: role?.name },
        actions: { label: <UserActions userId={id} /> },
      })),
    [users]
  );

  return (
    <div className="flex flex-col w-full gap-32">
      <header className="w-full pb-16 border-b-2 border-gray-200 flex gap-32">
        <h1 className="w-full text-[24px] font-bold">Users</h1>
        <Button
          onClick={() => toggleCreateUserModal(true)}
          className="text-sm shrink-0"
        >
          Create User
        </Button>
      </header>
      <Table
        withSearch
        data={tableData}
        columns={[
          { header: '№', accessor: 'index', classNames: 'w-[80px]' },
          { accessor: 'id', classNames: 'w-[120px]' },
          { accessor: 'name', classNames: 'w-[20%]' },
          { accessor: 'email', classNames: 'w-[20%]' },
          { accessor: 'role', classNames: 'w-[120px]' },
          {
            accessor: 'actions',
            disableSort: true,
            classNames: 'w-[132px]',
            headerClassNames: 'justify-center',
          },
        ]}
      />
      {isCreateUserModalOpen && <CreateUserModal onClose={handleCloseModal} />}
    </div>
  );
};
