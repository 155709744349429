import React, { FC, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface ModalProps {
  children?: React.ReactNode;
  onClose: () => void;
  isOpen?: boolean;
  title?: string;
  footer?: React.ReactNode;
}

export const Modal: FC<ModalProps> = ({
  isOpen = true,
  onClose,
  title,
  children,
  footer,
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          onClose();
          cancelButtonRef?.current?.blur();
        }}
        initialFocus={cancelButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4"
            >
              <Dialog.Panel className="relative transform rounded-md bg-white text-left shadow-xl transition-all">
                <XMarkIcon
                  className="absolute top-8 right-2 text-gray-500 hover:text-gray-700 w-24 h-24 cursor-pointer bg-transparent"
                  onClick={() => {
                    onClose();
                    cancelButtonRef?.current?.blur();
                  }}
                />
                <div className="p-32 pb-16 flex flex-col justify-center gap-16">
                  {title && (
                    <Dialog.Title
                      as="h2"
                      className="text-xl font-semibold leading-6"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  <div>{children}</div>
                </div>
                {footer && (
                  <footer className="w-full bg-gray-50 flex gap-8 items-center justify-center p-8">
                    {footer}
                  </footer>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
