import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

interface CreateRequestOptions extends AxiosRequestConfig {
  url: string;
  isPrivate?: boolean;
}

export function request<T = any>({
  url,
  method = 'GET',
  headers = {},
  data = null,
  isPrivate = true,
}: CreateRequestOptions): AxiosPromise<T> {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '20') * 1000,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers,
    },
  });

  if (isPrivate) {
    const token = localStorage.getItem('token');

    instance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });
  }

  const requestConfig = {
    method,
    url: isPrivate ? `/private${url}` : url,
    ...(data !== null && { data }),
  };

  return instance(requestConfig)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
