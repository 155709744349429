import { FC } from 'react';

import { Button } from 'components/general/Button';
import { Modal } from 'components/general/Modal';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  text: string | React.ReactNode;
}

export const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  text,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    footer={
      <>
        <Button red onClick={onDelete}>
          Delete
        </Button>
        <Button orange onClick={onClose}>
          Cancel
        </Button>
      </>
    }
  >
    {text}
  </Modal>
);
