import { FC } from 'react';
import { Link } from 'react-router-dom';

export const NotFoundBlock: FC = () => {
  return (
    <div className="grid h-full min-h-screen w-full place-items-center bg-white p-32">
      <div className="text-center">
        <p className="text-6xl font-semibold text-blue-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Perhaps the page has gone for a long walk?
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-blue-600 px-16 py-8 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
        </div>
      </div>
    </div>
  );
};
