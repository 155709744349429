import { FC, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';
import { TooltipsContext } from 'contexts/tooltips';
import { ROUTE } from 'routes/helpers';
import Spinner from 'components/general/Spinner';

export const Layout: FC = () => {
  const location = useLocation();

  const { isAuthenticated, hasUsers, pendingUser } = useContext(AuthContext);
  const { tooltipComponent } = useContext(TooltipsContext);

  if (pendingUser) {
    return <Spinner />;
  }

  if (!hasUsers) {
    return <Navigate to={ROUTE.REGISTRATION} replace />;
  }

  if (location.pathname === '/') {
    return (
      <Navigate to={isAuthenticated ? ROUTE.DASBOARD : ROUTE.LOGIN} replace />
    );
  }

  return (
    <div className="flex w-full h-full min-h-screen overflow-hidden relative">
      <Outlet />
      {tooltipComponent}
    </div>
  );
};
