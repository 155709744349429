import { FC, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { CommonContext } from 'contexts/common';
import { ROUTE } from 'routes/helpers';
import { deleteContainer } from 'api/container';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';

interface ContainerActionsProps {
  containerId: number;
  inList?: boolean;
}

export const ContainerActions: FC<ContainerActionsProps> = ({
  containerId,
  inList = true,
}) => {
  const navigate = useNavigate();

  const { showTooltip } = useContext(TooltipsContext);
  const { fetchContainers } = useContext(CommonContext);

  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);

  const handleDeleteContainer = useCallback(async () => {
    try {
      const { data } = await deleteContainer(containerId);

      if (data) {
        await fetchContainers();

        toggleConfirmModal(false);
        showTooltip({
          type: TooltipType.SUCCESS,
          content: 'Container deleted successfully',
        });
      }
    } catch {
      showTooltip({
        type: TooltipType.ERROR,
      });
    }
  }, [containerId, showTooltip, fetchContainers]);

  return (
    <>
      <div className="flex flex-wrap gap-8 w-full justify-center">
        {inList && (
          <Button
            onClick={() => navigate(`${ROUTE.CONTAINERS}/${containerId}`)}
            icon={<InformationCircleIcon />}
          />
        )}
        <Button
          red
          onClick={() => toggleConfirmModal(true)}
          icon={<TrashIcon />}
        >
          {!inList && 'Delete'}
        </Button>
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmModalOpen}
        onClose={() => toggleConfirmModal(false)}
        onDelete={handleDeleteContainer}
        text={
          <>
            Are you sure you want to delete contaner id: <b>{containerId}</b>?
          </>
        }
      />
    </>
  );
};
