import { Severity } from '@sentry/types';
import { RouterProvider } from 'react-router-dom';
import { FC, Suspense } from 'react';

import { ErrorBoundary } from 'components/internal/ErrorBoundary';
import { InternalError } from 'components/internal/InternalError';
import { CommonContextProvider } from 'contexts/common';
import { composeProviders } from 'helpers/context';
import { AuthContextProvider } from 'contexts/auth';
import { router } from 'routes';
import { TooltipsContextProvider } from 'contexts/tooltips';

const Provider = composeProviders([
  TooltipsContextProvider,
  AuthContextProvider,
  CommonContextProvider,
]);

const App: FC = () => {
  return (
    <Suspense fallback={null}>
      <ErrorBoundary fallback={<InternalError />} severity={Severity.Fatal}>
        <Provider>
          <RouterProvider router={router} />
        </Provider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
