import { FC } from 'react';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';

interface PrivateRouteProps {
  children?: React.ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, pendingUser } = useContext(AuthContext);

  if (!isAuthenticated && !pendingUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
