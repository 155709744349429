import { FC, useContext, useMemo, useState } from 'react';

import { CopyCode } from 'components/general/CopyCode';
import { Table } from 'components/general/Table';
import { CommonContext } from 'contexts/common';
import { Button } from 'components/general/Button';
import { ContainerActions } from 'components/container/ContainerActions';
import { CreateContainerModal } from './components/CreateContainerModal';

export const Containers: FC = () => {
  const { containers } = useContext(CommonContext);
  const [isCreateContainerModalOpen, toggleCreateContainerModal] =
    useState(false);

  const tableData = useMemo(
    () =>
      containers.map(({ id, url, name }, index) => ({
        index: { value: index + 1 },
        id: { value: id },
        url: {
          value: url,
          label: <CopyCode code={url} />,
        },
        name: { value: name },
        actions: { label: <ContainerActions containerId={id} /> },
      })),
    [containers]
  );

  return (
    <div className="flex flex-col w-full gap-32">
      <header className="w-full pb-16 border-b-2 border-gray-200 flex gap-32">
        <h1 className="w-full text-[24px] font-bold">Containers</h1>
        <Button
          onClick={() => toggleCreateContainerModal(true)}
          className="text-sm shrink-0"
        >
          Create Container
        </Button>
      </header>
      <Table
        withSearch
        data={tableData}
        columns={[
          { header: '№', accessor: 'index', classNames: 'w-[80px]' },
          { accessor: 'id', classNames: 'w-[120px]' },
          { accessor: 'name', classNames: 'w-[30%]' },
          { accessor: 'url' },
          {
            accessor: 'actions',
            disableSort: true,
            classNames: 'w-[132px]',
            headerClassNames: 'justify-center',
          },
        ]}
      />
      {isCreateContainerModalOpen && (
        <CreateContainerModal
          onClose={() => toggleCreateContainerModal(false)}
        />
      )}
    </div>
  );
};
