import { Landing } from 'api/landing';
import { request } from './base';

export interface Probability {
  percentage: number;
  containerId: number;
  landingId: number;
  id: number;
  createdAt: Date;
  updatedAt: Date;
  landing: Landing;
}

export interface ProbabilityData {
  containerId?: number;
  landingId?: number | undefined;
  percentage: number;
}

export const createProbability = (data: ProbabilityData) =>
  request({
    url: `/probabilities`,
    method: 'POST',
    data,
  });

export const editProbability = (probabilityId: number, data: ProbabilityData) =>
  request({
    url: `/probabilities/${probabilityId}`,
    method: 'PUT',
    data,
  });

export const deleteProbability = (probabilityId: number) =>
  request({
    url: `/probabilities/${probabilityId}`,
    method: 'DELETE',
  });
