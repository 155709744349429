import React, { FC, useContext, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { RegistrationData } from 'api/user';
import { PasswordInput, TextInput } from 'components/form';
import { Button } from 'components/general/Button';
import { AuthContext } from 'contexts/auth';
import { email } from 'helpers/validators';
import { ROUTE } from 'routes/helpers';

const regFormDefaultValues = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  roleId: 1,
};

export const Registration: FC = () => {
  const navigate = useNavigate();

  const { registration, isAuthenticated, hasUsers } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<RegistrationData>({
    defaultValues: regFormDefaultValues,
  });
  const password = watch('password');

  const validatePassword = useCallback(
    (value: string) => value === password || 'The passwords do not match',
    [password]
  );

  const registrationSubmit = useCallback(
    (values: RegistrationData) =>
      registration(values, () =>
        navigate(ROUTE.CONTAINERS, {
          replace: true,
        })
      ),
    [registration, navigate]
  );

  if (isAuthenticated) {
    return <Navigate to={ROUTE.CONTAINERS} replace />;
  }

  if (!isAuthenticated && hasUsers) {
    return <Navigate to={ROUTE.LOGIN} replace />;
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Registration
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(registrationSubmit)}>
          <div className="flex flex-col gap-4">
            <Controller
              name="name"
              control={control}
              rules={{
                required: 'Please enter your name',
              }}
              render={({ field }) => (
                <TextInput field={field} label="Name" errors={errors} />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Please enter your email',
                validate: email,
              }}
              render={({ field }) => (
                <TextInput field={field} label="Email" errors={errors} />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Please enter password',
              }}
              render={({ field }) => (
                <PasswordInput field={field} errors={errors} />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: 'Please confirm the password',
                validate: validatePassword,
              }}
              render={({ field }) => (
                <PasswordInput
                  field={field}
                  label="Confirm Password"
                  errors={errors}
                />
              )}
            />
          </div>
          <Button className="w-full">Registration</Button>
        </form>
      </div>
    </div>
  );
};
