import { FC } from 'react';

import { Modal } from 'components/general/Modal';
import { LandingForm } from './LandingForm';

export const CreateLandingModal: FC<{ onClose: () => void }> = ({
  onClose,
}) => (
  <Modal onClose={onClose}>
    <h2 className="font-bold text-[18px] text-center mb-32">Create Landing</h2>
    <LandingForm onClose={onClose} />
  </Modal>
);
