import { FC, useCallback, useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { TextInput } from 'components/form';
import { Modal } from 'components/general/Modal';
import { CommonContext } from 'contexts/common';
import { editLanding, LandingData } from 'api/landing';
import { editProbability, ProbabilityData } from 'api/probability';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';

interface EditProbabilityModalProps {
  probabilityId: number;
  onClose: () => void;
}

type EditProbabilityFormValues = Pick<LandingData, 'name'> &
  Pick<ProbabilityData, 'percentage'>;

export const EditProbabilityModal: FC<EditProbabilityModalProps> = ({
  probabilityId,
  onClose,
}) => {
  const { activeContainer, fetchActiveContainer } = useContext(CommonContext);
  const { showTooltip } = useContext(TooltipsContext);

  const activeProbability = useMemo(
    () =>
      activeContainer?.probabilities?.find(({ id }) => id === probabilityId),
    [activeContainer?.probabilities, probabilityId]
  );

  const defaultValues: EditProbabilityFormValues | undefined = useMemo(() => {
    if (!activeProbability) return;

    return {
      name: activeProbability.landing.name || '',
      percentage: activeProbability.percentage || 0,
    };
  }, [activeProbability]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const handleModalClose = useCallback(async () => {
    reset();

    onClose();
  }, [onClose, reset]);

  const checkTotalProbabilitiesSum = useCallback(
    (incomeValue: number) => {
      if (!activeContainer?.probabilities) return;

      if (+incomeValue <= 0) {
        return 'Percentage should be more than 0';
      }

      const savedSum = activeContainer.probabilities
        .filter(({ id }) => id !== activeProbability?.id)
        .reduce((sum, probability) => sum + probability.percentage, 0);

      const updatedSum = savedSum + +incomeValue;

      if (updatedSum > 100) {
        return 'Total sum should not be more than 100%';
      }

      return;
    },
    [activeContainer?.probabilities, activeProbability?.id]
  );

  const handleEditProbability = useCallback(
    async ({ name, percentage }: EditProbabilityFormValues) => {
      if (!activeProbability || !activeContainer?.id) return;

      try {
        if (activeProbability.landing.name !== name) {
          await editLanding(+activeProbability.landing.id, {
            name: name,
          });
        }

        if (percentage !== +activeProbability.percentage) {
          await editProbability(+activeProbability.id, {
            percentage,
          });
        }

        await fetchActiveContainer(activeContainer.id);

        handleModalClose();

        showTooltip({
          type: TooltipType.SUCCESS,
          content: 'Landing probability updated successfully',
        });
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }
    },
    [
      showTooltip,
      handleModalClose,
      fetchActiveContainer,
      activeProbability,
      activeContainer?.id,
    ]
  );

  return (
    <Modal onClose={handleModalClose}>
      <div className="flex flex-col gap-16 justify-center">
        <h2 className="font-bold text-[18px] text-center">
          Edit Landing probability
        </h2>
        <form
          className="flex flex-col gap-16 items-center w-[320px]"
          onSubmit={handleSubmit(handleEditProbability)}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Landing name is required',
            }}
            render={({ field }) => (
              <TextInput field={field} errors={errors} label="Landing name" />
            )}
          />
          <p className="flex gap-8 mb-16">
            <ExclamationTriangleIcon className="w-24 h-24 shrink-0 text-orange-600" />
            <span className="whitespace-normal text-[12px]">
              Please note that modifying the name of this landing will impact
              all instances where it is used in other containers.
            </span>
          </p>
          <Controller
            name="percentage"
            control={control}
            rules={{
              required: 'Percentage is required',
              validate: checkTotalProbabilitiesSum,
            }}
            render={({ field }) => (
              <TextInput
                field={field}
                errors={errors}
                label="Percentage (%)"
                type="number"
                min={0}
              />
            )}
          />
          <div className="flex gap-8">
            <Button green type="submit">
              Save
            </Button>
            <Button type="button" onClick={handleModalClose} orange>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
