import { FC } from 'react';

import { Modal } from 'components/general/Modal';
import { UserForm } from 'components/user/UserForm';

export const CreateUserModal: FC<{ onClose: () => void }> = ({ onClose }) => (
  <Modal onClose={onClose}>
    <h2 className="font-bold text-[18px] text-center mb-32">Create User</h2>
    <UserForm onClose={onClose} />
  </Modal>
);
