import { FC, useCallback, useContext, useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { AuthContext } from 'contexts/auth';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { deleteUser } from 'api/user';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { Modal } from 'components/general/Modal';
import { UserForm } from './UserForm';

export const UserActions: FC<{ userId: number }> = ({ userId }) => {
  const { fetchUsers, user } = useContext(AuthContext);
  const { showTooltip } = useContext(TooltipsContext);

  const [isEditUserOpen, toggleEditUser] = useState(false);
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);

  const handleDeleteUser = useCallback(async () => {
    try {
      const { data } = await deleteUser(userId);

      if (data) {
        await fetchUsers();

        toggleConfirmModal(false);
        showTooltip({
          type: TooltipType.SUCCESS,
          content: 'User deleted successfully',
        });
      }
    } catch {
      showTooltip({
        type: TooltipType.ERROR,
      });
    }
  }, [userId, fetchUsers, showTooltip]);

  return (
    <>
      <div className="flex flex-wrap gap-8 w-full justify-center">
        <Button
          onClick={() => toggleEditUser(true)}
          icon={<PencilSquareIcon />}
        />
        {user?.id !== userId && (
          <Button
            red
            onClick={() => toggleConfirmModal(true)}
            icon={<TrashIcon />}
          />
        )}
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmModalOpen}
        onClose={() => toggleConfirmModal(false)}
        onDelete={handleDeleteUser}
        text={
          <>
            Are you sure you want to delete user id: <b>{userId}</b>?
          </>
        }
      />
      <Modal isOpen={isEditUserOpen} onClose={() => toggleEditUser(false)}>
        <h2 className="font-bold text-[18px] text-center mb-32">Edit User</h2>
        <UserForm
          onClose={() => toggleEditUser(false)}
          userId={userId}
          isEditMode
        />
      </Modal>
    </>
  );
};
