import { createLanding, editLanding, LandingData } from 'api/landing';
import { TextInput } from 'components/form';
import { Button } from 'components/general/Button';
import { TooltipType } from 'components/general/Tooltip';
import { CommonContext } from 'contexts/common';
import { TooltipsContext } from 'contexts/tooltips';
import { FC, useCallback, useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface LandingFormProps {
  onClose: () => void;
  landingId?: number;
  isEditMode?: boolean;
}

export const LandingForm: FC<LandingFormProps> = ({
  onClose,
  landingId,
  isEditMode,
}) => {
  const { showTooltip } = useContext(TooltipsContext);
  const { fetchLandings, landings } = useContext(CommonContext);

  const activeLanding = useMemo(() => {
    if (!landingId) return;

    return landings.find(({ id }) => id === landingId);
  }, [landingId, landings]);

  const defaultValues = useMemo(() => {
    return isEditMode && activeLanding
      ? {
          name: activeLanding.name,
          url: activeLanding.url,
        }
      : { name: '', url: '' };
  }, [isEditMode, activeLanding]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({ defaultValues });

  const handleLanding = useCallback(
    async (values: LandingData) => {
      try {
        const { data, request } =
          isEditMode && landingId
            ? await editLanding(landingId, values)
            : await createLanding(values);

        if (data) {
          await fetchLandings();

          showTooltip({
            type: TooltipType.SUCCESS,
            content:
              isEditMode && landingId
                ? 'Landing updated successfully'
                : 'Landing created successfully',
          });

          reset();
          onClose();
        } else {
          setError('root', { message: request.responseText });
        }
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }
    },
    [
      showTooltip,
      onClose,
      setError,
      reset,
      fetchLandings,
      isEditMode,
      landingId,
    ]
  );

  return (
    <form
      className="flex flex-col gap-16 items-center w-[320px]"
      onSubmit={handleSubmit(handleLanding)}
    >
      <Controller
        name="name"
        control={control}
        rules={{
          required: 'Landing name is required',
        }}
        render={({ field }) => (
          <TextInput field={field} errors={errors} label="Name" />
        )}
      />

      <Controller
        name="url"
        control={control}
        rules={{
          required: 'Url is required',
        }}
        render={({ field }) => (
          <TextInput field={field} errors={errors} label="URL" />
        )}
      />
      {errors.root && (
        <p className="text-[12px] font-medium text-red-500">
          {errors.root.message}
        </p>
      )}
      <div className="flex gap-8">
        <Button green type="submit">
          Save
        </Button>
        <Button type="button" onClick={onClose} orange>
          Cancel
        </Button>
      </div>
    </form>
  );
};
