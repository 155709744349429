import React, {
  PropsWithChildren,
  useEffect,
  createContext,
  FC,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { Tooltip, TooltipProps } from 'components/general/Tooltip';

export interface TooltipsContextData {
  tooltipComponent: JSX.Element | null;
}

interface TooltipsContextActions {
  showTooltip: (args: TooltipProps) => void;
  closeTooltip: () => void;
}

export const TooltipsContext = createContext<
  TooltipsContextData & TooltipsContextActions
>(null as any);

export const TooltipsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [tooltip, toggleTooltip] = useState<TooltipProps | null>(null);

  const showTooltip = useCallback(({ type, content }: TooltipProps) => {
    toggleTooltip({ type, content });
  }, []);

  const closeTooltip = () => toggleTooltip(null);

  useEffect(() => {
    if (tooltip) {
      const timeout = setTimeout(() => {
        closeTooltip();
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tooltip]);

  const tooltipComponent = useMemo(
    () =>
      tooltip ? (
        <Tooltip type={tooltip.type} content={tooltip.content} />
      ) : null,
    [tooltip]
  );

  return (
    <TooltipsContext.Provider
      value={{ tooltipComponent, showTooltip, closeTooltip }}
    >
      {children}
    </TooltipsContext.Provider>
  );
};
