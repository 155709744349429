import { UserActions } from 'components/user/UserActions';
import { AuthContext } from 'contexts/auth';
import { FC, useContext } from 'react';

export const Profile: FC = () => {
  const { user } = useContext(AuthContext);

  return user ? (
    <div className="flex flex-col w-full gap-16">
      <header className="flex gap-16 items-center mb-32 w-full">
        <h2 className="font-bold w-full text-[24px]">Your Profile</h2>
        <div className="flex shrink-0">
          <UserActions userId={user.id} />
        </div>
      </header>
      <div className="flex gap-8">
        <span>Name:</span>
        <span className="font-bold">{user.name}</span>
      </div>
      <div className="flex gap-8 items-center">
        <span>Email:</span>
        <span className="font-bold">{user.email}</span>
      </div>
      <div className="flex gap-8 items-center">
        <span>Role:</span>
        <span className="font-bold">{user.role?.name}</span>
      </div>
    </div>
  ) : null;
};
