import React, { FC, useCallback, useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

interface CopyCodeProps {
  code: any | string;
  className?: string;
}

export const CopyCode: FC<CopyCodeProps> = ({ code, className }) => {
  const [isActive, setIsActive] = useState(false);

  const handleCopyCode = useCallback(() => {
    if (code == null) return;

    navigator.clipboard.writeText(code);
    if (!isActive) {
      setIsActive(true);

      const timeout = setTimeout(() => {
        setIsActive(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [code, isActive, setIsActive]);

  return (
    <>
      {code && (
        <div className={`flex items-center gap-8 `}>
          <span className={`truncate ${className || ''}`}>{code}</span>
          <div className="relative text-gray-500">
            <span
              className={` ${
                isActive ? 'block' : 'hidden'
              } absolute text-xs top-[-16px] right-0`}
            >
              Copied!
            </span>
            <DocumentDuplicateIcon
              className="w-24 h-24 shrink-0 cursor-pointer"
              onClick={handleCopyCode}
            />
          </div>
        </div>
      )}
    </>
  );
};
