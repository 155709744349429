import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import {
  Container as ContainerI,
  ContainerData,
  editContainer,
} from 'api/container';
import { Button } from 'components/general/Button';
import { CopyCode } from 'components/general/CopyCode';
import { TextInput } from 'components/form';
import { AuthContext } from 'contexts/auth';
import { LandingProbabilitiesTable } from './components/LandingProbabilitiesTable';
import { ContainerActions } from 'components/container/ContainerActions';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { CommonContext } from 'contexts/common';

export const Container: FC = () => {
  const { id } = useParams();
  const { activeContainer, fetchActiveContainer } = useContext(CommonContext);

  const [isEditMode, toggleEditMode] = useState(false);

  useEffect(() => {
    if (!id) return;

    fetchActiveContainer(+id);
  }, [fetchActiveContainer, id]);

  return (
    <div className="w-full px-2 flex flex-col gap-32">
      {activeContainer ? (
        <>
          <header className="flex gap-8 w-full">
            <div className="w-full flex flex-col gap-8">
              <h2 className="font-bold text-[24px] mb-32 flex items-center">
                {isEditMode ? (
                  <ContainerName
                    id={activeContainer.id}
                    name={activeContainer.name}
                    onClose={() => toggleEditMode(false)}
                  />
                ) : (
                  <>
                    {activeContainer.name}
                    <PencilSquareIcon
                      className="w-24 h-24 ml-2 cursor-pointer text-gray-500"
                      onClick={() => toggleEditMode(true)}
                    />
                  </>
                )}
              </h2>
              <div className="flex gap-8">
                <span>ID:</span>
                <span className="font-bold">{activeContainer.id}</span>
              </div>
              <div className="flex gap-8">
                <span>URL:</span>
                <CopyCode
                  code={`${process.env.REACT_APP_DOMAIN}/${activeContainer.uuid}`}
                  className="font-bold"
                />
              </div>
              <div className="text-sm flex flex-col gap-1 mt-2">
                <p>
                  Created at:{' '}
                  {new Date(activeContainer.createdAt).toLocaleString()}
                </p>
                <p>
                  Updated at:{' '}
                  {new Date(activeContainer.updatedAt).toLocaleString()}
                </p>
              </div>
            </div>
            <div className="w-[120px]">
              <ContainerActions
                containerId={activeContainer.id}
                inList={false}
              />
            </div>
          </header>
          <LandingProbabilitiesTable />
        </>
      ) : (
        <p>Container with id {id} does not exist</p>
      )}
    </div>
  );
};

type ContainerNameProps = Pick<ContainerI, 'id' | 'name'> & {
  onClose: () => void;
};

const ContainerName: FC<ContainerNameProps> = ({ id, name, onClose }) => {
  const { user } = useContext(AuthContext);
  const { showTooltip } = useContext(TooltipsContext);
  const { setActiveContainer, fetchContainers } = useContext(CommonContext);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<Pick<ContainerData, 'name'>>({ defaultValues: { name: '' } });

  useEffect(() => {
    if (name) {
      setValue('name', name);
    }
  }, [name, setValue]);

  const handleUpdateContainer = useCallback(
    async (values: Pick<ContainerData, 'name'>) => {
      if (!id || !user?.id) return;
      try {
        const { data: updatedContainer } = await editContainer(id, {
          ...values,
          userId: user.id,
        });

        if (updatedContainer) {
          setActiveContainer((s) => ({ ...s, ...updatedContainer }));
          fetchContainers();
        }

        onClose();
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    },
    [id, setActiveContainer, fetchContainers, onClose, user?.id, showTooltip]
  );

  return (
    <form
      className="flex gap-16 items-center text-base"
      onSubmit={handleSubmit(handleUpdateContainer)}
    >
      <Controller
        name="name"
        control={control}
        rules={{
          required: 'Container name is required',
        }}
        render={({ field }) => <TextInput field={field} errors={errors} />}
      />
      <div className="flex gap-8 text-[14px]">
        <Button green className="min-w-[64px]" type="submit">
          Save
        </Button>
        <Button
          className="min-w-[64px]"
          orange
          type="button"
          onClick={() => {
            onClose();
            reset();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
