import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'components/general/Button';
import { Select, TextInput } from 'components/form';
import { Modal } from 'components/general/Modal';
import { CommonContext } from 'contexts/common';
import { createProbability, ProbabilityData } from 'api/probability';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { CreateLandingModal } from 'modules/landings/components/CreateLandingModal';

interface CreateProbabilityModalProps {
  onClose: () => void;
}

export const CreateProbabilityModal: FC<CreateProbabilityModalProps> = ({
  onClose,
}) => {
  const { landings } = useContext(CommonContext);
  const { activeContainer, fetchActiveContainer } = useContext(CommonContext);
  const { showTooltip } = useContext(TooltipsContext);

  const [isCreateLandingModalOpen, toggleCreateLandingModal] = useState(false);

  const landingOptions = useMemo(
    () =>
      landings.map(({ name, url, id }) => ({
        label: (
          <>
            {name} <span className="text-sm">({url})</span>
          </>
        ),
        labelText: `${name}, ${url}`,
        value: id,
      })),
    [landings]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<ProbabilityData>({
    defaultValues: {
      landingId: landingOptions?.[0]?.value,
      percentage: 0,
    },
  });

  const handleModalClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const checkTotalProbabilitiesSum = useCallback(
    (incomeValue: number) => {
      if (!activeContainer?.probabilities) return;

      if (+incomeValue <= 0) {
        return 'Percentage should be more than 0';
      }

      const savedSum = activeContainer.probabilities.reduce(
        (sum, probability) => sum + probability.percentage,
        0
      );

      const updatedSum = savedSum + +incomeValue;

      if (updatedSum > 100) {
        return 'Total sum should not be more than 100%';
      }

      return;
    },
    [activeContainer?.probabilities]
  );

  const handleEditProbability = useCallback(
    async (values: ProbabilityData) => {
      if (!activeContainer?.id) return;

      try {
        const { data, request } = await createProbability({
          ...values,
          containerId: activeContainer.id,
        });

        if (data) {
          await fetchActiveContainer(activeContainer.id);

          handleModalClose();

          showTooltip({
            type: TooltipType.SUCCESS,
            content: 'Landing probability created successfully',
          });
        } else {
          setError('root', { message: request.responseText });
        }
      } catch (error) {
        showTooltip({ type: TooltipType.ERROR });
      }
    },
    [
      showTooltip,
      handleModalClose,
      fetchActiveContainer,
      setError,
      activeContainer?.id,
    ]
  );

  return (
    <Modal onClose={handleModalClose}>
      <div className="flex flex-col gap-16 justify-center w-[380px]">
        <h2 className="font-bold text-[18px] text-center">
          Create Landing probability
        </h2>
        <form
          className="flex flex-col gap-16 items-center w-full"
          onSubmit={handleSubmit(handleEditProbability)}
        >
          <Controller
            name="landingId"
            control={control}
            rules={{
              required: 'Landing is required',
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  label="Landing"
                  onChange={onChange}
                  value={value}
                  options={landingOptions}
                />
              );
            }}
          />
          <div className="flex flex-col gap-4 justify-center text-center">
            <span className="font-semibold">or</span>
            <Button
              type="button"
              onClick={() => toggleCreateLandingModal(true)}
            >
              Create Landing
            </Button>
          </div>
          <Controller
            name="percentage"
            control={control}
            rules={{
              required: 'Percentage is required',
              validate: checkTotalProbabilitiesSum,
            }}
            render={({ field }) => (
              <TextInput
                field={field}
                errors={errors}
                label="Percentage (%)"
                type="number"
              />
            )}
          />
          {errors.root && (
            <p className="text-[12px] font-medium text-red-500">
              {errors.root.message}
            </p>
          )}
          <div className="flex gap-8">
            <Button green type="submit">
              Save
            </Button>
            <Button orange type="button" onClick={handleModalClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
      {isCreateLandingModalOpen && (
        <CreateLandingModal onClose={() => toggleCreateLandingModal(false)} />
      )}
    </Modal>
  );
};
