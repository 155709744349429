import { FC, Fragment, useContext, useMemo, useRef } from 'react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';

import { TooltipsContext } from 'contexts/tooltips';

export enum TooltipType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export interface TooltipProps {
  type: TooltipType;
  content?: string | React.ReactNode;
}

const mapTooltipTypes = {
  [TooltipType.SUCCESS]: {
    border: 'border-green-600',
    icon: <CheckCircleIcon className="w-24 h-24 text-green-600" />,
    defaultMsg: 'Data updated successfully',
  },
  [TooltipType.ERROR]: {
    border: 'border-red-600',
    icon: <MinusCircleIcon className="w-24 h-24 text-red-600" />,
    defaultMsg: 'Something went wrong',
  },
  [TooltipType.WARNING]: {
    border: 'border-orange-500',
    icon: <ExclamationTriangleIcon className="w-24 h-24 text-orange-500" />,
    defaultMsg: 'Please note that this action can impact other data.',
  },
  [TooltipType.INFO]: {
    border: 'border-gray-500',
    icon: <InformationCircleIcon className="w-24 h-24 text-gray-500" />,
    defaultMsg: null,
  },
};

export const Tooltip: FC<TooltipProps> = ({ type, content }) => {
  const { closeTooltip } = useContext(TooltipsContext);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const { border, icon, defaultMsg } = useMemo(
    () => mapTooltipTypes[type],
    [type]
  );

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={closeTooltip}
        initialFocus={contentRef}
      >
        <div
          ref={contentRef}
          className={`fixed min-w-[160px] z-51 bg-white shadow-lg shadow-gray-500/50 p-16 pt-[24px] rounded-sm right-2 bottom-2 border-b-2 ${border}`}
        >
          <XMarkIcon
            className="absolute top-8 right-2 hover:text-gray-700 w-[20px] h-[20px] cursor-pointer bg-transparent"
            onClick={closeTooltip}
          />
          <div className="flex gap-8 items-center text-base">
            {icon}
            {content || defaultMsg}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
