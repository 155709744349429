import { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

import { RoleName } from 'api/user';
import { AuthContext } from 'contexts/auth';
import { Button } from './Button';
import { ROUTE } from 'routes/helpers';

export const Sidebar: FC = () => {
  const { pathname } = useLocation();

  const { logout, user } = useContext(AuthContext);

  return (
    <nav className="flex flex-col h-full min-h-screen w-[260px] bg-gray-800 p-16 text-white shrink-0">
      <div className="flex gap-8 items-center mb-32">
        <UserCircleIcon className="w-32" />
        <p className="flex flex-col h-auto">
          <span className="flex text-2xl font-bold">{user?.name}</span>
          <span>{user?.role?.name}</span>
        </p>
      </div>
      <div className="flex flex-col h-full gap-8">
        {generalMenu.map(({ title, link }, idx) => (
          <Link
            key={idx}
            to={link}
            className={`flex gap-8 p-8 hover:bg-gray-500 rounded-lg ${
              pathname === link ? 'bg-gray-500' : ''
            }`}
          >
            {title}
          </Link>
        ))}
        {user?.role?.name === RoleName.ADMIN &&
          adminMenu.map(({ title, link }, idx) => (
            <Link
              key={idx}
              to={link}
              className={`flex gap-8 p-8 hover:bg-gray-500 rounded-lg ${
                pathname === link ? 'bg-gray-500' : ''
              }`}
            >
              {title}
            </Link>
          ))}
        <Link
          to={ROUTE.PROFILE}
          className={`flex gap-8 p-8 hover:bg-gray-500 rounded-lg ${
            pathname === ROUTE.PROFILE ? 'bg-gray-500' : ''
          }`}
        >
          Profile
        </Link>
      </div>
      <Button
        gray
        onClick={logout}
        icon={<ArrowRightOnRectangleIcon />}
        className="mt-auto"
      >
        Logout
      </Button>
    </nav>
  );
};

interface MenuItem {
  title: string | React.ReactNode;
  link: ROUTE;
}

const generalMenu: MenuItem[] = [
  {
    title: 'Containers',
    link: ROUTE.CONTAINERS,
  },
  {
    title: 'Landings',
    link: ROUTE.LANDINGS,
  },
];

const adminMenu: MenuItem[] = [
  {
    title: 'Users',
    link: ROUTE.USERS,
  },
];
