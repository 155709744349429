import { request } from './base';

export enum RoleName {
  ADMIN = 'admin',
  MANAGER = 'manager',
}

export interface UserRole {
  id: number;
  name: RoleName;
  createdAt: Date;
  updatedAt: Date;
}

export interface User {
  id: number;
  name: string;
  email: string;
  password: string;
  roleId: number;
  createdAt: Date;
  updatedAt: Date;
  role?: UserRole;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface RegistrationData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  roleId: number;
}

export const login = (data: LoginData) =>
  request({
    url: '/auth/login',
    method: 'POST',
    data,
  });

export const registration = (data: RegistrationData) =>
  request({
    url: '/auth/registration',
    method: 'POST',
    data,
  });

export const hasUsers = () =>
  request({
    url: `/has-users`,
    isPrivate: false,
  });

export const createUser = (data: Omit<RegistrationData, 'confirmPassword'>) =>
  request({
    url: '/users',
    method: 'POST',
    data,
  });

export const editUser = (
  userId: number,
  data: Omit<RegistrationData, 'confirmPassword' | 'password'>
) =>
  request({
    url: `/users/${userId}`,
    method: 'PUT',
    data,
  });

export const getUsers = () =>
  request({
    url: `/users`,
  });

export const getUserById = (userId: number) =>
  request({
    url: `/users/${userId}`,
  });

export const deleteUser = (userId: number) =>
  request({
    method: 'DELETE',
    url: `/users/${userId}`,
  });
