import React, { FC } from 'react';

const Spinner: FC = () => {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="animate-spin rounded-full h-[48px] w-[48px] border-t-2 border-blue-600 shadow-md" />
    </div>
  );
};

export default Spinner;
