import { request } from './base';
import { Probability } from './probability';

export interface Container {
  uuid: string;
  name: string;
  userId: number;
  id: number;
  probabilities?: Probability[];
  createdAt: Date;
  updatedAt: Date;

  url?: string;
}

export interface ContainerData {
  name: string;
  userId: number;
}

export const getContainers = () =>
  request({
    url: `/containers`,
  });

export const getContainerById = (containerId: number) =>
  request({
    url: `/containers/${containerId}`,
  });

export const createContainer = (data: ContainerData) =>
  request({
    url: `/containers`,
    method: 'POST',
    data,
  });

export const editContainer = (containerId: number, data: ContainerData) =>
  request({
    url: `/containers/${containerId}`,
    method: 'PUT',
    data,
  });

export const deleteContainer = (containerId: number) =>
  request({
    url: `/containers/${containerId}`,
    method: 'DELETE',
  });
