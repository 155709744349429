import { FC, useCallback, useContext, useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { Modal } from 'components/general/Modal';
import { TooltipsContext } from 'contexts/tooltips';
import { CommonContext } from 'contexts/common';
import { deleteLanding } from 'api/landing';
import { TooltipType } from 'components/general/Tooltip';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { LandingForm } from './LandingForm';

export const LandingActions: FC<{ landingId: number }> = ({ landingId }) => {
  const { showTooltip } = useContext(TooltipsContext);
  const { fetchLandings } = useContext(CommonContext);

  const [isEditLandingOpen, toggleEditLanding] = useState(false);
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);

  const handleDeleteLanding = useCallback(async () => {
    try {
      const { data } = await deleteLanding(landingId);

      if (data) {
        await fetchLandings();

        toggleConfirmModal(false);
        showTooltip({
          type: TooltipType.SUCCESS,
          content: 'Landing deleted successfully',
        });
      }
    } catch {
      showTooltip({
        type: TooltipType.ERROR,
      });
    }
  }, [landingId, showTooltip, fetchLandings]);

  return (
    <>
      <div className="flex flex-wrap gap-8 w-full justify-center">
        <Button
          onClick={() => toggleEditLanding(true)}
          icon={<PencilSquareIcon />}
        />
        <Button
          red
          onClick={() => toggleConfirmModal(true)}
          icon={<TrashIcon />}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmModalOpen}
        onClose={() => toggleConfirmModal(false)}
        onDelete={handleDeleteLanding}
        text={
          <>
            Are you sure you want to delete landing id: <b>{landingId}</b>?
          </>
        }
      />
      <Modal
        isOpen={isEditLandingOpen}
        onClose={() => toggleEditLanding(false)}
      >
        <h2 className="font-bold text-[18px] text-center mb-32">
          Edit Landing
        </h2>
        <LandingForm
          onClose={() => toggleEditLanding(false)}
          landingId={landingId}
          isEditMode
        />
      </Modal>
    </>
  );
};
