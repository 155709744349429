export enum ROUTE {
  // public
  REGISTRATION = '/registration',
  LOGIN = '/login',

  //private
  DASBOARD = '/dashboard',
  CONTAINERS = '/dashboard/containers',
  LANDINGS = '/dashboard/landings',
  USERS = '/dashboard/users',
  PROFILE = '/dashboard/profile',
}
