import { createBrowserRouter } from 'react-router-dom';

import { InternalError } from 'components/internal/InternalError';
import { NotFoundBlock } from 'components/internal/NotFound';
import { Layout } from 'Layout';
import { PrivateRoute } from './PrivateRoute';
import { Registration } from 'modules/auth/registration';
import { Login } from 'modules/auth/login';
import { Dashboard } from 'modules/dashboard';
import { Containers } from 'modules/containers';
import { Container } from 'modules/container';
import { ROUTE } from './helpers';
import { Landings } from 'modules/landings';
import { Users } from 'modules/users';
import { Profile } from 'modules/profile';

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <InternalError />,
    children: [
      { path: ROUTE.REGISTRATION, element: <Registration /> },
      { path: ROUTE.LOGIN, element: <Login /> },
      {
        path: ROUTE.DASBOARD,
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
        children: [
          {
            path: `${ROUTE.CONTAINERS}/:id`,
            element: <Container />,
          },
          {
            path: ROUTE.CONTAINERS,
            element: <Containers />,
          },
          {
            path: ROUTE.LANDINGS,
            element: <Landings />,
          },
          {
            path: ROUTE.USERS,
            element: <Users />,
          },
          {
            path: ROUTE.PROFILE,
            element: <Profile />,
          },
        ],
      },
      { path: '*', element: <NotFoundBlock /> },
    ],
  },
]);
