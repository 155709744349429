import { FC, Fragment, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { useDebounce } from 'hooks/debounce';
import { TextInput } from './TextInput';

type SelectOption = {
  value: string | number | undefined;
  label: string | React.ReactNode;
  labelText?: string;
};

interface SelectProps {
  onChange: (arg: any) => void;
  value: string | number | undefined;
  options: SelectOption[];
  label?: string;
  hideSearch?: boolean;
}

export const Select: FC<SelectProps> = ({
  value,
  label,
  options,
  onChange,
  hideSearch,
}) => {
  const selectedOption = useMemo(() => {
    return options.find(({ value: optValue }) => value === optValue);
  }, [options, value]);

  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const searchTextDebounce = useDebounce<string>(searchPhrase, 300);

  const handleChangeResult = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  const handleResetSearch = () => {
    setSearchPhrase('');
  };

  const filteredData = useMemo(() => {
    if (!searchTextDebounce) return options;

    return options.filter(({ value, label, labelText }) =>
      [value, label, labelText].some((item) =>
        String(item)
          .toLowerCase()
          .includes(searchTextDebounce.toLowerCase().trim())
      )
    );
  }, [options, searchTextDebounce]);

  return (
    <Listbox
      value={selectedOption}
      onChange={(option) => onChange(option.value)}
    >
      {({ open }) => (
        <>
          <div className="w-full relative flex flex-col gap-4">
            {label && (
              <Listbox.Label className="block text-[12px] font-medium text-gray-900">
                {label}
              </Listbox.Label>
            )}
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md py-8 px-16 pr-40 text-gray-900 shadow-sm ring-inset h-[40px] outline-none 
							 ${open ? 'ring-2 ring-blue-600' : 'ring-1 ring-gray-300'}`}
            >
              <span className="flex items-center">
                <span className="block truncate">{selectedOption?.label}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 w-40 flex items-center justify-center">
                {open ? (
                  <ChevronUpIcon
                    className="h-20 w-24 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="h-20 w-24 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-[68px] max-h-[180px] w-full overflow-auto rounded-md bg-white pb-1 text-base shadow-2xl ring-1 ring-gray-300">
                {!hideSearch && (
                  <div className="flex mb-8 sticky top-0 left-0 w-full bg-white backdrop-blur z-10 pt-[2px]">
                    <TextInput
                      field={{ name: 'search', value: searchPhrase }}
                      withBorderBottom
                      onChange={handleChangeResult}
                      placeholder="Search"
                      icon={
                        searchPhrase ? (
                          <XCircleIcon
                            className="w-24 h-24"
                            onClick={handleResetSearch}
                          />
                        ) : (
                          <MagnifyingGlassIcon className="w-24 h-24" />
                        )
                      }
                    />
                  </div>
                )}

                {filteredData.length ? (
                  filteredData.map((item) => (
                    <Listbox.Option
                      key={item.value}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-8 px-16 ${
                          active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={`${
                                selected ? 'font-semibold pr-24' : 'font-normal'
                              } block truncate`}
                            >
                              {item.label}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={`${
                                active ? 'text-white' : 'text-blue-600'
                              } absolute inset-y-0 right-8 flex items-center`}
                            >
                              <CheckIcon
                                className="h-24 w-24"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))
                ) : (
                  <li className="text-gray-900 text-center">No data</li>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
