import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DocumentPlusIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { Table } from 'components/general/Table';
import { EditProbabilityModal } from './EditProbabilityModal';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { deleteProbability } from 'api/probability';
import { CommonContext } from 'contexts/common';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { CreateProbabilityModal } from './CreateProbabilityModal';

export const LandingProbabilitiesTable: FC = () => {
  const { activeContainer } = useContext(CommonContext);

  const [isCreateModalOpen, toggleCreateModal] = useState(false);

  const landingsTableData = useMemo(() => {
    if (!activeContainer?.probabilities) return [];

    return activeContainer.probabilities.map(
      ({ id, landing, percentage }, idx) => ({
        index: { value: idx + 1 },
        name: { value: landing?.name },
        url: { value: landing?.url },
        probability: { value: `${percentage}%` },
        actions: {
          label: (
            <ProbabilityActions probabilityId={id} percentage={percentage} />
          ),
        },
      })
    );
  }, [activeContainer?.probabilities]);

  return (
    <>
      <div>
        <div className="w-full mb-16 flex items-center justify-between">
          <h2 className="text-xl font-semibold uppercase">
            Landing Probabilities List
          </h2>
          <Button onClick={() => toggleCreateModal(true)}>
            Create <DocumentPlusIcon className="w-24 h-24" />
          </Button>
        </div>
        <Table
          columns={[
            { header: '№', accessor: 'index', classNames: 'w-[80px]' },
            { accessor: 'name', classNames: 'w-[30%]' },
            { accessor: 'url' },
            {
              accessor: 'probability',
              classNames: 'w-[132px]',
              headerClassNames: 'justify-center',
              cellClassNames: 'text-center',
            },
            {
              accessor: 'actions',
              disableSort: true,
              classNames: 'w-[132px]',
              headerClassNames: 'justify-center',
            },
          ]}
          data={landingsTableData}
        />
      </div>
      {isCreateModalOpen && (
        <CreateProbabilityModal onClose={() => toggleCreateModal(false)} />
      )}
    </>
  );
};

const ProbabilityActions: FC<{
  probabilityId: number;
  percentage: number;
}> = ({ probabilityId, percentage }) => {
  const { id } = useParams();

  const { fetchActiveContainer } = useContext(CommonContext);
  const { showTooltip } = useContext(TooltipsContext);

  const [isConfirmDeleteModalOpen, toggleConfirmDeleteModal] = useState(false);
  const [isEditModalOpen, toggleEditModal] = useState(false);

  const handleDeleteProbability = useCallback(async () => {
    if (id) {
      try {
        const { data } = await deleteProbability(probabilityId);

        if (data) {
          await fetchActiveContainer(+id);

          toggleConfirmDeleteModal(false);
          showTooltip({
            type: TooltipType.SUCCESS,
            content: 'Landing probability deleted successfully',
          });
        }
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    }
  }, [id, probabilityId, showTooltip, fetchActiveContainer]);

  return (
    <>
      <div className="flex flex-wrap gap-8 w-full justify-center">
        <Button
          onClick={() => toggleEditModal(true)}
          icon={<PencilSquareIcon />}
        />
        <Button
          red
          onClick={() => toggleConfirmDeleteModal(true)}
          icon={<TrashIcon />}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => toggleConfirmDeleteModal(false)}
        onDelete={handleDeleteProbability}
        text={`Are you sure you want to delete landing probability ${percentage}%?`}
      />
      {isEditModalOpen && (
        <EditProbabilityModal
          probabilityId={probabilityId}
          onClose={() => toggleEditModal(false)}
        />
      )}
    </>
  );
};
