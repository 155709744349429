import React, {
  FC,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FieldErrors } from 'react-hook-form';

export interface FieldProps {
  name: string;
  value: any;
}

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  field: FieldProps;
  label?: string;
  icon?: JSX.Element | null;
  errors?: FieldErrors;
  classNames?: string;
  withBorderBottom?: boolean;
}

export const TextInput: FC<TextInputProps> = ({
  field: { name, ...fieldProps },
  errors,
  label,
  icon,
  placeholder,
  onFocus,
  onBlur,
  classNames,
  withBorderBottom,
  ...inputProps
}) => {
  const [isOnFocus, toggleFocus] = useState<boolean>(false);

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      toggleFocus(true);
      onFocus && onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      toggleFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur]
  );

  const borderStyle: string = useMemo(() => {
    if (withBorderBottom) {
      return 'ring-0 focus:ring-0 shadow-none focus:shadow-none focus:outline-none border-solid border-b-2 border-blue-600';
    }

    return `rounded-md ring-1 ring-inset ${
      isOnFocus ? 'ring-blue-600' : 'ring-gray-300'
    }`;
  }, [isOnFocus, withBorderBottom]);

  return (
    <div className="w-full flex flex-col gap-4">
      {label && (
        <label
          className="block text-[12px] font-medium text-gray-900"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="flex relative">
        <input
          {...fieldProps}
          {...inputProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={`block w-full py-8 px-16 text-gray-900 shadow-sm border-0 placeholder:text-gray-400 ${borderStyle} ${
            icon && 'pr-40'
          } ${classNames || ''}`}
        />
        {icon && (
          <div className="absolute right-0 w-40 object-contain h-full inset-y-0 flex items-center justify-center cursor-pointer text-gray-500">
            {icon}
          </div>
        )}
      </div>
      {errors?.[name] && (
        <p className="text-[12px] font-medium text-red-500">
          {errors[name]?.message as React.ReactNode}
        </p>
      )}
    </div>
  );
};
