import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Sidebar } from 'components/general/Sidebar';
import { ROUTE } from 'routes/helpers';

export const Dashboard: FC = () => {
  const location = useLocation();

  if (location.pathname === ROUTE.DASBOARD) {
    return <Navigate to={ROUTE.CONTAINERS} replace />;
  }

  return (
    <>
      <Sidebar />
      <div className="w-full h-full max-h-screen py-16 px-32 overflow-auto">
        <div className="flex w-full min-w-[800px]">
          <Outlet />
        </div>
      </div>
    </>
  );
};
