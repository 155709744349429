import {
  PropsWithChildren,
  useContext,
  useEffect,
  createContext,
  FC,
  useState,
  useCallback,
} from 'react';

import { Container, getContainerById, getContainers } from 'api/container';
import { AuthContext } from 'contexts/auth';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { getLandings, Landing } from 'api/landing';

export interface CommonContextData {
  containers: Container[];
  activeContainer: Container | null;
  landings: Landing[];
}

interface CommonContextActions {
  fetchContainers: () => void;
  fetchLandings: () => void;
  fetchActiveContainer: (id: number) => void;
  setActiveContainer: React.Dispatch<React.SetStateAction<Container | null>>;
}

export const CommonContext = createContext<
  CommonContextData & CommonContextActions
>(null as any);

export const CommonContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { showTooltip } = useContext(TooltipsContext);

  const [containers, setContainers] = useState<Container[]>([]);
  const [landings, setLandings] = useState<Landing[]>([]);
  const [activeContainer, setActiveContainer] = useState<Container | null>(
    null
  );

  const { isAuthenticated } = useContext(AuthContext);

  const fetchContainers = useCallback(async () => {
    try {
      const { data } = await getContainers();
      const containersWithLinks: Container[] = data.map(
        (container: Container) => ({
          ...container,
          url: `${process.env.REACT_APP_DOMAIN}/${container.uuid}`,
        })
      );

      setContainers(containersWithLinks);
    } catch {
      setContainers([]);
      showTooltip({ type: TooltipType.ERROR });
    }
  }, [showTooltip]);

  const fetchLandings = useCallback(async () => {
    try {
      const { data } = await getLandings();
      if (data) {
        setLandings(data);
      }
    } catch {
      showTooltip({ type: TooltipType.ERROR });
    }
  }, [showTooltip]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchContainers();
      fetchLandings();
    }
  }, [isAuthenticated, fetchContainers, fetchLandings]);

  const fetchActiveContainer = useCallback(
    async (id: number) => {
      try {
        const { data } = await getContainerById(+id);
        setActiveContainer(data);
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }
    },
    [showTooltip]
  );

  return (
    <CommonContext.Provider
      value={{
        containers,
        fetchContainers,
        activeContainer,
        fetchActiveContainer,
        setActiveContainer,
        fetchLandings,
        landings,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
