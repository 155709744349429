import { FC, useCallback, useMemo, useState } from 'react';
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

import { TextInput } from 'components/form';
import { useDebounce } from 'hooks/debounce';

type Row = {
  [key: string]: {
    value?: string | number | React.ReactNode;
    label?: string | number | React.ReactNode;
  };
};

enum SortingMode {
  ASC = 'asc',
  DESC = 'desc',
}

interface Column {
  accessor: string;
  header?: string;
  disableSort?: boolean;
  classNames?: string;
  headerClassNames?: string;
  cellClassNames?: string;
}

interface TableProps {
  data: Row[];
  columns: Column[];
  withSearch?: boolean;
}

export const Table: FC<TableProps> = ({ data, columns, withSearch }) => {
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState(SortingMode.ASC);

  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const searchTextDebounce = useDebounce<string>(searchPhrase, 300);

  const handleChangeResult = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  const handleResetSearch = () => {
    setSearchPhrase('');
  };

  const handleSort = useCallback(
    (columnName: string) => {
      if (columnName === sortedColumn) {
        setSortDirection(
          sortDirection === SortingMode.ASC ? SortingMode.DESC : SortingMode.ASC
        );
      } else {
        setSortedColumn(columnName);
        setSortDirection(SortingMode.ASC);
      }
    },
    [sortedColumn, sortDirection]
  );

  const filteredData = useMemo(() => {
    if (!searchTextDebounce) return data;

    return data.filter((item) =>
      Object.values(item).some(({ value }) => {
        const fieldValue = String(value).toLowerCase().trim();
        const searchText = searchTextDebounce.toLowerCase().trim();

        return fieldValue.includes(searchText);
      })
    );
  }, [data, searchTextDebounce]);

  const sortedData = useMemo(() => {
    if (sortedColumn) {
      return [...filteredData].sort((row1, row2) => {
        const value1 = String(row1[sortedColumn].value);
        const value2 = String(row2[sortedColumn].value);

        return sortDirection === SortingMode.ASC
          ? value1.localeCompare(value2)
          : value2.localeCompare(value1);
      });
    }

    return filteredData;
  }, [filteredData, sortedColumn, sortDirection]);

  return (
    <div className="flex flex-col w-full gap-32">
      {withSearch && (
        <div className="w-[320px]">
          <TextInput
            field={{ name: 'search', value: searchPhrase }}
            onChange={handleChangeResult}
            placeholder="Search"
            icon={
              searchPhrase ? (
                <XCircleIcon
                  className="w-24 h-24"
                  onClick={handleResetSearch}
                />
              ) : (
                <MagnifyingGlassIcon className="w-24 h-24" />
              )
            }
          />
        </div>
      )}
      <div className="border border-gray-200 rounded-md overflow-hidden">
        <table className="w-full table-auto divide-y divide-gray-200">
          <thead>
            <tr>
              {columns?.map(
                (
                  {
                    header,
                    accessor,
                    disableSort,
                    classNames,
                    headerClassNames,
                  },
                  idx
                ) => (
                  <th
                    key={idx}
                    className={`group bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider ${
                      !disableSort && 'cursor-pointer'
                    } ${classNames || ''}`}
                    onClick={
                      !disableSort ? () => handleSort(accessor) : undefined
                    }
                  >
                    <div
                      className={`flex items-center gap-18 p-16 relative ${
                        headerClassNames || ''
                      }`}
                    >
                      {header || accessor}
                      {!disableSort && (
                        <span className="hidden group-hover:block absolute right-0 ">
                          {sortDirection === SortingMode.ASC ? (
                            <BarsArrowDownIcon className="w-20 h-20" />
                          ) : (
                            <BarsArrowUpIcon className="w-20 h-20" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {sortedData?.length ? (
              sortedData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  {columns.map((column, idx) => (
                    <td
                      key={idx}
                      className={`p-16 truncate max-w-0 ${
                        column.cellClassNames || ''
                      }`}
                    >
                      {item[column.accessor].label ||
                        item[column.accessor].value}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className="p-16 text-center" colSpan={columns?.length}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
