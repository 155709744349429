import { request } from './base';

export interface Landing {
  id: number;
  name: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface LandingData {
  name?: string;
  url?: string;
}

export const getLandings = () =>
  request({
    url: `/landings`,
  });

export const createLanding = (data: LandingData) =>
  request({
    url: `/landings`,
    method: 'POST',
    data,
  });

export const editLanding = (landingId: number, data: LandingData) =>
  request({
    url: `/landings/${landingId}`,
    method: 'PUT',
    data,
  });

export const deleteLanding = (landingId: number) =>
  request({
    url: `/landings/${landingId}`,
    method: 'DELETE',
  });
